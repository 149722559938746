<template>
    <div>
        <div>
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--8">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card">
                            <div class="card-header border-0">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h3 class="mb-0">{{ tt('transaction_history_pivot')}}</h3>
                                    </div>
                                    <div class="col text-right">
                                        <base-button type="default" size="sm" :disabled="table.load" @click="generate">{{tt('generate')}} <span v-if="table.load"><i class="fas fa-spinner fa-spin"></i></span></base-button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!table.load">
                                <div style="height:350px;overflow-y:auto">
                                    <el-table class="table-responsive highlight-current-row table" :data="table.data" header-row-class-name="thead-light" border style="width: 100%" height="450px" v-if="table.data.length > 0">
                                        <el-table-column :label="tt('material')" sortable width="145px" prop="material_number"></el-table-column>
                                        <el-table-column :label="tt('sum_of_process')" sortable width="185px" prop="sum_of_process"></el-table-column>
                                        <el-table-column :label="tt('internal_lead_time')" sortable min-width="175px" prop="sum_internal_lead_time"></el-table-column>
                                        <el-table-column :label="tt('external_lead_time')" sortable min-width="175px" prop="sum_external_lead_time"></el-table-column>
                                        <el-table-column :label="tt('sum_of_lead_time')" sortable min-width="185px" prop="sum_lead_time"></el-table-column>
                                        <el-table-column :label="tt('sum_of_po_qty')" sortable min-width="185px" prop="sum_of_po_qty"></el-table-column>
                                        <el-table-column :label="tt('unit_price')" sortable min-width="185px" prop="unit_price"></el-table-column>
                                    </el-table>
                                    <div class="text-center" style="height: 100px" v-else>
                                        <br><br>
                                        <img src="/assets/smartcat/data_is_empty.png" alt="Data Is Empty" width="350px">
                                        <p class="text-muted">{{ tt('empty') }}</p>
                                    </div>
                                </div>
                                <div class="card-footer pb-0 ">   
                                    <span class="float-left">
                                        <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                                    </span>                             
                                    <span class="float-right">
                                        {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.page.total}) }}
                                    </span>
                                </div>
                            </div>
                            <skeleton-loading v-else></skeleton-loading>
                        </div>
                    </div>
                </div>
                 
            </div>
        </div>
    </div>
</template>


<script>
import Api from '@/helpers/api';
import TransactionHistoryService from '@/services/rcs/transactionHistory.service'
export default {
    data(){
        return{
            table:{
                data:[],
                page:{},
                load:false,
            },
        }
    },
    mounted(){
        this.get()
    },
    methods:{
        get(page = null){
            let context = this;
            this.table.load = true;
            Api(context, TransactionHistoryService.pivot(page)).onSuccess((response) => {
                context.table.data = response.data.data.data
                context.table.page = response.data.data
            }).onError((err) =>{
                context.transactionHistorys = []
            }).onFinish(() => {
                context.table.load = false
            }).call()
        },
        changePage(page){
            this.get(page)
        },
        generate(){
            let context  = this;
            this.table.load = true;
            Api(context, TransactionHistoryService.generate()).onSuccess((response) =>{
                context.$notify({
                    message: response.data.message,
                    type: "success",
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                })
                context.get()
            }).onError((err) =>{
                context.$notify({
                    message: err.response.data.message,
                    type: "danger",
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                })
                context.table.load = false
            }).call()
        }
        
    }
}
</script>

<style scoped>
.el-table__body tr.current-row > td {
  background-color: #ffe391 !important;
}
</style>